<template>
	<aside class="column profile__sidebar">
		<div class="profile__column ">
			<div class="is-flex mb-0">
				<div class="profile__image">
					<b-button native-type="button" class="profile__image__edit" @click="openAvatar">
						<svg-icon icon="edit"></svg-icon>
					</b-button>
					<b-skeleton v-if="!user.avatar" width="90" height="90" animated></b-skeleton>
					<img :src="user.avatar" :alt="user.full_name" />
				</div>
				<div class="pl-3 pt-1">
					<div v-if="!user.full_name" class="profile__name">
						<b-skeleton width="160" height="25" animated></b-skeleton>
						<b-skeleton v-if="!user.permission" width="100" height="15" animated></b-skeleton>
					</div>
					<h3 class="profile__name is-medium is-size-5 is-ellipsis">{{ user.full_name }}</h3>
					<p v-if="user.permission" class="profile__role">{{ user.permission.name }}</p>
				</div>
			</div>
			<ul v-if="isDesktop" class="profile__list mt-3">
				<li v-if="!user.full_name">
					<b-skeleton height="22" animated></b-skeleton>
				</li>
				<li v-else>
					<span class="profile__list__key">Nome:</span>
					<span class="profile__list__value">{{ user.full_name }}</span>
				</li>
				<li v-if="!user.email">
					<b-skeleton height="22" animated></b-skeleton>
				</li>
				<li v-else>
					<span class="profile__list__key">E-mail:</span>
					<span class="profile__list__value">{{ user.email }}</span>
				</li>
			</ul>
			<ul class="profile__navigation mt-3">
				<li v-for="(m, i) in navigation" :key="i">
					<router-link :to="m.path">
						<svg-icon :icon="m.icon"></svg-icon>
						<span translate="no">{{ m.name }}</span>
					</router-link>
				</li>
			</ul>
		</div>
	</aside>
</template>

<script>
import Menu from '@/router/user'
import Icon from '@/components/Icon'
import Modal from '@/components/modals/Avatar'
import { responsive } from '@/mixins/responsive'
import { mapState } from 'vuex'

export default {
	name: 'Sidebar',
	mixins: [responsive],
	components: {
		'svg-icon': Icon
	},
	methods: {
		openAvatar() {
			this.$buefy.modal.open({
				parent: this,
				component: Modal,
				scroll: 'clip',
				customClass: 'is-avatar',
				trapFocus: true
			})
		}
	},
	computed: {
		...mapState('user', ['user']),
		navigation() {
			return Menu
		}
	}
}
</script>
