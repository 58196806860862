import { ToastProgrammatic as Toast } from 'buefy'

let config = {
	position: 'is-bottom',
	closable: false,
	duration: 4000
}

function toast(type, message) {
	Toast.open({
		type: type,
		message: message,
		...config
	})
}

function successToast(message) {
	Toast.open({
		type: 'is-success',
		message: message,
		...config
	})
}

function errorToast(message) {
	Toast.open({
		type: 'is-danger',
		message: message,
		...config
	})
}

function warningToast(message) {
	Toast.open({
		type: 'is-warning',
		message: message,
		...config
	})
}

export { toast, successToast, errorToast, warningToast }
